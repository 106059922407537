import { PriceList } from 'pages/reports/pricelist/priceList.jsx';
import { withNavigationWatcher } from './contexts/navigation.jsx';
import {
  HomePage,
  TasksPage,
  ProfilePage,
  OrdersPage,
  OrderPage,
  ActsPage,
  FormOrdersPage,
  FormOrderPage,
  PriceOrderPage,
  PriceOrdersPage,
  EPPage,
  EPMainForm,
  EPTachograph,
  Diotrade,
  EpCO2Form,
  EKMT,
  LabReportPage,
  LabReportsPage,
  ManualPage,
  ManualMOC,
  PolicyPage,
  PolicyCard,
  PolicyNew,
  DashboardPage,
  NewsPage,
  ContractOffer,
  ImportCarsPage,
  CashFlowReport,
  DebitsByAct,
  AdminkaPage,
  WorkTimePage,
  WorkTimeListPage,
  EasyPayList,
  EasyPayMessage,
  DayLabReport,
  PayReport,
  ShedulerPage,
  TerminalListDocsPage,
  InsurancePriceList,
  AdminkaReportsPage
} from './pages/index.js';

import { TestPage } from './pages/test/test.jsx';
import { OrderCollections } from 'pages/orders/orderCollections/OrderCollections.jsx';
import { OerderCollectionsDoc } from 'pages/orders/orderCollections/OrderCollectionsDoc.jsx';

export const routes = [
  {
    path: '/tasks',
    component: TasksPage,
  },
  {
    path: '/profile',
    component: ProfilePage,
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/dashboard',
    component: DashboardPage,
  },
  {
    path: '/news',
    component: NewsPage,
  },
  {
    path: '/orders',
    component: OrdersPage,
  },
  {
    path: '/acts',
    component: ActsPage,
  },
  {
    path: '/lab_reports',
    component: LabReportsPage,
  },
  {
    path: '/lab_report/:id',
    component: LabReportPage,
  },
  {
    path: '/form_orders',
    component: FormOrdersPage,
  },
  {
    path: '/price_orders',
    component: PriceOrdersPage,
  },
  {
    path: '/order/:id',
    component: OrderPage,
  },
  {
    path: '/orderCollections/',
    component: OrderCollections,
  },  
  {
    path: '/orderCollections/:id',
    component: OerderCollectionsDoc,
  },  
  {
    path: '/form_order/:id',
    component: FormOrderPage,
  },
  {
    path: '/price_order/:id',
    component: PriceOrderPage,
  },
  {
    path: '/test/',
    component: TestPage,
  },
  {
    path: '/ep',
    component: EPPage,
  },
  {
    path: '/ep/:id',
    component: EPMainForm,
  },
  {
    path: '/ep-taho/:id',
    component: EPTachograph,
  },
  {
    path: '/ep-dio/:id',
    component: Diotrade,
  },
  {
    path: '/ep-co2/:id',
    component: EpCO2Form,
  },
  {
    path: '/ep-ekmt/:id',
    component: EKMT,
  },
  {
    path: '/manuals-moc',
    component: ManualMOC,
  },
  {
    path: '/manual/:id',
    component: ManualPage,
  },
  {
    path: '/policy',
    component: PolicyPage,
  },
  {
    path: '/policy/prices',
    component: InsurancePriceList,
  },
  {
    path: '/policy/:id',
    component: PolicyCard,
  },
  {
    path: '/policy-new',
    component: PolicyNew,
  },
  {
    path: '/oferta',
    component: ContractOffer,
  },
  {
    path: '/import-cars',
    component: ImportCarsPage,
  },
  {
    path: '/cash_flow_report',
    component: CashFlowReport,
  },
  {
    path: '/debitsbyact_report/:partner',
    component: DebitsByAct,
  },
  {
    path: '/debitsbyact_report',
    component: DebitsByAct,
  },
  {
    path: '/daylabreport/:start/:end',
    component: DayLabReport,
  },
  {
    path: '/daylabreport',
    component: DayLabReport,
  },
  {
    path: '/pay_report',
    component: PayReport,
  },
  {
    path: '/adminka',
    component: AdminkaPage,
  },
  {
    path: '/adminka/reports',
    component: AdminkaReportsPage,
  },
  {
    path: '/work_time/:id',
    component: WorkTimePage,
  },
  {
    path: '/work_times',
    component: WorkTimeListPage,
  },
  {
    path: '/easypays',
    component: EasyPayList,
  },
  {
    path: '/easypaymessage/:id',
    component: EasyPayMessage,
  },
  {
    path: '/pricelist',
    component: PriceList,
  },
  {
    path: '/sheduler',
    component: ShedulerPage,
  },
  {
    path: '/termlist',
    component: TerminalListDocsPage,
  },

];

export default routes.map((route) => ({
  ...route,
  element: withNavigationWatcher(route.component),
}));
