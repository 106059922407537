import React from "react";
import styles from './forms.module.scss';

export const LabeledField = ({ field, label, children, errors, grow = 0 }) => {
    return (
      <div style={{ flexGrow: grow }}>
        <div className={styles.fieldRow}>
          <div className={styles.fieldLabel}>{label}</div>
          <div className={styles.fieldValue}>
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, {
                ...field,
                style: { width: '100%' },
                onChange: undefined,
                onValueChanged: async (prop) => {
                  await child.props?.onValueChanged?.(prop);
                  if (Array.isArray(field.value)){
                    if (JSON.stringify(field.value)!==JSON.stringify(prop.value)) field.onChange(prop.value);
                  } else { 
                  field.onChange(prop.value);
                  }
                }
              });
            })}
          </div>
        </div>
        {errors?.[field.name] && <span className={styles.fieldError}> {errors[field.name].message}</span>}
      </div>
    );
  };